<template>
  <div>
    <AllPage ref="AllPage" :allProps="allProps">
      <template v-slot:state="scope">
        <span v-for="item in orderStateList" :key="item.id" v-show="scope.data.state===item.id">{{item.label}}</span>
      </template>
    </AllPage>
  </div>
</template>
<script>
import { allProps } from "./config";
import  {orderStateList}  from "@/utils/index"
export default {
  components: {
    AllPage: () => import("@/components/AllPage/AllPage.vue"),
  },
  provide() {
    return {
      changeRuleForm: function () {},
    };
  },
  data() {
    return {
      tableData: {},
      allProps,
      orderStateList
    };
  },
  created() {
    /* this.allProps.items.forEach((item) => {
        if (item.prop === "dizhi") item.options = this.$store.state.space;
        if (item.prop === "owner_id") {
          item.list = res.data.data;
        }
      });
  }, */
  },
  methods: {
    changeTableList(data) {
      data.forEach((item) => {
        item.s_id=item.shopid[0].s_id
        item.shopName=item.shopid[0].shopinfo[0].name
      });
      return data;
    },
  },
};
</script>
