import { Api } from "@/apis";
export const allProps = {
  isShowBtn: {
    addBtn: false,
  },
  dialog: {
    isShow: false,
    title: "编辑",
  },
  data: { s_id: "" },
  items: [
    {
      label: "订单编号",
      prop: "DD",
      type: ["table"],
    },
    {
      label: "门店名",
      prop: "shopName",
      type: ["table"],
    },
    {
      label: "设备号",
      prop: "s_id",
      type: ["table"],
    },
    {
      label: "游戏",
      prop: "game_name",
      type: ["table"],
    },
    {
      label: "支付时间",
      prop: "created_at",
      type: ["table"],
    },
    {
      label: "订单金额",
      prop: "moneyz",
      type: ["table"],
    },
    {
      label: "状态",
      prop: "state",
      type: ["table"],
      Ttype: "slot",
    },
  ],
  api: Api.orderList,
};
